import { Link, graphql } from "gatsby";
import React, { Component } from "react";

import Container from "react-bulma-components/lib/components/container";
import Footer from "components/Footer";
import Helmet from "react-helmet";
import Section from "react-bulma-components/lib/components/section";
import fuzz from "fuzzball";
import pages from "../../pages.json";
import redirects from "../../redirects.json";

const redirectsList = redirects.filter(
  (r) => r.realUrl.indexOf("jotform") === -1
);

class Page404 extends Component {
  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;
    let description = globals.seo_description;
    let img_alt = globals.seo_image_alt || page.title;
    let fb_image = globals.seo_image.facebook;

    let twitter_image = globals.seo_image.twitter;

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  findLikelyPage() {
    let path = this.props.location.pathname;
    if (path === `/404/` || path === `/404.html`) {
      return false;
    }
    // console.log("find likely page for", path);
    // path = `/BerTONHOUSE`;
    let result = false;
    let redirect = false;

    // first, let's check against vanity urls

    const options = { scorer: fuzz.token_set_ratio };
    const pagesResults = fuzz.extract(
      path.replace("/", " ").replace("-", " "),
      pages.filter((p) => p.replace("/", " ").replace("-", " ")),
      options
    );
    // .filter(r => r[1] > 50);
    // console.log(pagesResults);
    if (pagesResults.length > 0) {
      result = pages[pagesResults[0][2]];
      // console.log("found page", result);
      // check if the found page is a vanity url
      const vanityUrlResult = redirectsList.find((r) => r.vanityUrl === result);
      if (vanityUrlResult) {
        return {
          label: vanityUrlResult.vanityUrl,
          url: vanityUrlResult.realUrl,
        };
      }
      return {
        label: result,
        url: result,
      };
    } else {
      // console.log(`searching redirects`);//
      const redirectResults = fuzz.extract(
        path.replace("/", " ").replace("-", " "),
        redirectsList.map((r) =>
          r.vanityUrl.replace("/", " ").replace("-", " ")
        ),
        options
      );
      // .filter(r => r[1] > 50);
      //
      // console.log(redirectResults);;
      if (redirectResults.length > 0) {
        result = redirectsList[redirectResults[0][2]];
        // console.log("found redirect", result);
        let url = result.realUrl;
        if (url.charAt(0) !== "/") {
          url = `/${url}`;
        }
        return {
          label: result.vanityUrl || result.realUrl,
          url,
        };
      }
    }
    return false;
  }

  render() {
    const likelyPage = this.findLikelyPage();
    // console.log("likely page", likelyPage);
    const foundLikelyPage =
      likelyPage.url !== undefined && likelyPage.label !== undefined;
    const { globals, menu, footer } = this.props.data;
    const footerData = { globals, menu, footer };
    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class: "page",
          }}
          title={`404 | Writers' Trust of Canada`}
          meta={Page404.getPageMeta(
            this.props.data.globals.data,
            { title: { text: `404 | Writers' Trust of Canada` } },
            this.props.location
          )}
        />
        <Section
          style={{
            backgroundColor: `#171717`,
            color: `white`,
            minHeight: `600px`,
            paddingTop: `12rem`,
          }}
        >
          <Container>
            <h1
              style={{
                fontFamily: "Sharp Grotesk",
                fontSize: "4rem",
                marginBottom: "3rem",
              }}
            >
              Oops!
            </h1>
            <h3
              style={{ fontSize: `1.4rem` }}
              dangerouslySetInnerHTML={{
                __html: `The page you requested was not found.`,
              }}
            />
            {foundLikelyPage && (
              <p
                className="likely"
                tyle={{ fontSize: `1.2rem`, marginTop: `2rem` }}
              >
                <span>Maybe you meant</span>{" "}
                <Link
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    textDecoration: "underline",
                  }}
                  title={likelyPage.label}
                  to={likelyPage.url}
                >
                  {likelyPage.label}
                </Link>
                ?
              </p>
            )}
          </Container>
        </Section>
        <Footer data={footerData} theme="grey" />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    footer: prismicSiteFooter {
      data {
        body {
          ... on PrismicSiteFooterBodyMenuList {
            id
            primary {
              column
              heading {
                text
              }
            }
            items {
              link {
                raw {
                  slug
                  uid
                  id
                }
              }
            }
          }
        }
      }
    }
    menu: prismicMainMenu {
      data {
        body {
          ... on PrismicMainMenuBodyMenuList {
            id
            primary {
              heading {
                text
              }
              headling_link {
                raw {
                  slug
                  uid
                  id
                }
              }
            }
            items {
              link {
                raw {
                  uid
                  id
                  slug
                }
              }
              link_label {
                text
              }
              manual_page_link
            }
          }
        }
      }
    }
    globals: prismicSiteGlobals {
      data {
        seo_description {
          text
        }
        twitter_handle {
          text
        }
        facebook_app_id {
          text
        }
        facebook_url1 {
          text
        }
        twitter_handle {
          text
        }
        instagram_url {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;

export default Page404;
